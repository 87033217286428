import React, {useContext, useEffect, useRef} from 'react';
import {PAContext} from "../../../Services/PAContext";


// Helper function to calculate layover time between two segments
const calculateLayoverTime = (arrivalTime, nextDepartureTime) => {
    const diffMs = new Date(nextDepartureTime) - new Date(arrivalTime); // Difference in milliseconds
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60)); // Convert to hours
    const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)); // Remaining minutes
    return `${diffHours > 0 ? `${diffHours} hr${diffHours > 1 ? 's' : ''}` : ''} ${diffMinutes > 0 ? `${diffMinutes} min${diffMinutes > 1 ? 's' : ''}` : ''}`.trim();
};

function FlightGoogleMap(props){

    const { dataArray } = useContext(PAContext);
    const { Planning, FlightPlanningPipeline } = dataArray;

    const _default_map_options = {
        mapId: "6c79697cd5555167",
        center: {
            lat: 37.422222,
            lng: -122.083333,
        },
        zoom: 10,
        mapTypeControl: false,
        fullscreenControl: false
    }

    // Store flight-related map objects (markers and polylines) in a map for easy removal
    const flightMapObjects = {};

    useEffect(() => {

        if (!window.google || !window.google.maps) {
            console.error("Google Maps API is not available.");
            return;
        }

        if (props.selectedFlights.length && props._global_map_ref && props._global_map_ref.current) {
            const map = props._global_map_ref.current;

            // Function to clear markers and polylines of a specific flight
            const clearFlightFromMap = (flightId) => {
                if (flightMapObjects[flightId]) {
                    flightMapObjects[flightId].markers.forEach(marker => marker.setMap(null));
                    flightMapObjects[flightId].polylines.forEach(polyline => polyline.setMap(null));
                    delete flightMapObjects[flightId]; // Remove the entry from the map
                }
            };

            // Function to clear all existing flight markers and polylines
            const clearMap = () => {
                Object.keys(flightMapObjects).forEach(flightId => {
                    clearFlightFromMap(flightId);
                });
            };

            // Function to adjust the map bounds to fit all markers
            const adjustMapBounds = (bounds) => {
                if (props.selectedFlights.length > 0) {
                    map.fitBounds(bounds);
                }
            };


            // Function to add flight data on the map
            const addFlightToMap = (flight) => {
                const bounds = new window.google.maps.LatLngBounds();
                const markers = [];
                const polylines = [];

                flight.slices.forEach((slice, sliceIndex) => {
                    slice.segments.forEach((segment, segmentIndex) => {
                        const origin = { lat: segment.origin.latitude, lng: segment.origin.longitude };
                        const destination = { lat: segment.destination.latitude, lng: segment.destination.longitude };

                        // Only add a departure marker if this is not the start of a layover (i.e., same location)
                        if (segmentIndex === 0 || slice.segments[segmentIndex - 1].destination.iata_code !== segment.origin.iata_code) {
                            const departMarkerContentDiv = document.createElement('div');
                            departMarkerContentDiv.innerHTML = `
                          <div class="planning-google-map-marker-layer-item">
                            <span class="planning-google-map-marker-layer-item-name">
                               Depart: ${new Date(segment.departing_at).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                            </span>
                          </div>
                        `;
                            const departMarker = new window.google.maps.marker.AdvancedMarkerView({
                                position: origin,
                                map: map,
                                content: departMarkerContentDiv,
                                title: `Depart: ${new Date(segment.departing_at).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}`,
                            });

                            // Add the marker for departure
                            bounds.extend(departMarker.position);
                            markers.push(departMarker);
                        }

                        // For a layover, add a marker that combines arrival time, layover time, and departure time
                        if (segmentIndex < slice.segments.length - 1) {
                            const nextSegment = slice.segments[segmentIndex + 1];
                            const layoverTime = calculateLayoverTime(segment.arriving_at, nextSegment.departing_at);

                            const layoverMarkerContentDiv = document.createElement('div');
                            layoverMarkerContentDiv.innerHTML = `
                          <div class="planning-google-map-marker-layer-item">
                            <span class="planning-google-map-marker-layer-item-name">
                               Arrive: ${new Date(segment.arriving_at).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}<br/>
                               Layover: ${layoverTime}<br/>
                               Depart: ${new Date(nextSegment.departing_at).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                            </span>
                          </div>
                        `;
                            const layoverMarker = new window.google.maps.marker.AdvancedMarkerView({
                                position: destination,
                                map: map,
                                content: layoverMarkerContentDiv,
                                title: `Layover: ${layoverTime}`,
                            });

                            bounds.extend(layoverMarker.position);
                            markers.push(layoverMarker);
                        } else {
                            // Add an arrival marker at the final destination
                            const arriveMarkerContentDiv = document.createElement('div');
                            arriveMarkerContentDiv.innerHTML = `
                          <div class="planning-google-map-marker-layer-item">
                            <span class="planning-google-map-marker-layer-item-name">
                               Arrive: ${new Date(segment.arriving_at).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                            </span>
                          </div>
                        `;
                            const arriveMarker = new window.google.maps.marker.AdvancedMarkerView({
                                position: destination,
                                map: map,
                                content: arriveMarkerContentDiv,
                                title: `Arrive: ${new Date(segment.arriving_at).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}`,
                            });

                            bounds.extend(arriveMarker.position);
                            markers.push(arriveMarker);
                        }

                        // Draw the flight path for this segment
                        const flightPath = new window.google.maps.Polyline({
                            path: [origin, destination],
                            geodesic: true,
                            strokeColor: '#FF0000',
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                            map: map,
                        });

                        polylines.push(flightPath);
                    });
                });

                // Adjust the map bounds to include all markers
                adjustMapBounds(bounds);

                // Store flight-specific map objects (markers and polylines) for later removal
                flightMapObjects[flight.id] = { markers, polylines };

            };


            // Clear previous markers and polylines
            clearMap();

            // Add the selected flights to the map
            props.selectedFlights.forEach(addFlightToMap);

            // Clean up on component unmount or when selected flights change
            return () => {
                clearMap();
            };


        }
    }, [props.selectedFlights, props._global_map_ref]);

    useEffect(() => {
        console.log("props._global_map_ref has been set! "+props._global_map_ref);
        console.log("props._global_map_ref.current : "+props._global_map_ref?.current);

        if (props._global_map_ref) {

            if (!window.google || !window.google.maps) {
                console.error("Google Maps API is not available.");
                return;
            }


            if (Planning && Planning.location){

                props._global_map_ref.current = new window.google.maps.Map(
                    document.getElementById("global_google_map_holder"),
                    {
                        mapId: "6c79697cd5555167",
                        center: {
                            lat: Planning.location.latitude,
                            lng: Planning.location.longitude,
                        },
                        zoom: 10,
                        mapTypeControl: false,
                        fullscreenControl: false
                    }
                );
            } else {
                props._global_map_ref.current = new window.google.maps.Map(
                    document.getElementById("global_google_map_holder"),
                    _default_map_options
                );

            }

            // Check if _global_map_ref.current is a valid Google Map instance
            if (props._global_map_ref.current && typeof props._global_map_ref.current.getCenter === 'function') {
                console.log("This is a Google Map instance.");
                console.log("Map Center: ", props._global_map_ref.current.getCenter());
                console.log("Map Zoom: ", props._global_map_ref.current.getZoom());
            } else {
                console.error("props._global_map_ref.current is not a valid Google Map instance.");
            }

        }

    }, [props._global_map_ref]);

    return (

        <div
            ref={props._global_map_ref}
            className="planning-map-area-google-map-holder"
            id="global_google_map_holder"
            style={{ width: '100%', height: 'calc(100vh - 243px)' }}
        />
    )
}

export default FlightGoogleMap;