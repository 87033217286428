import React, {useContext, useEffect, useState} from 'react';

import './plannerResponsive.css';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Menu,
    MenuItem,
    MenuList,
    Paper
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {PAContext} from "../../../Services/PAContext";
import {getEventFromDatabase} from "../../../Services/BrowserDatabaseFunctions";
import PAEvent from "../../../MasterEventObjects/PAEvent";
import TimeTable from "../../../MasterEventObjects/TimeTable";
import Lodging from "../../../MasterEventObjects/Lodging";
import PALocation from "../../../MasterEventObjects/Location";
import Activity from "../../../MasterEventObjects/Activity";
import Meal from "../../../MasterEventObjects/Meal";
import Idea from "../../../MasterEventObjects/Idea";
import Flight from "../../../MasterEventObjects/Flight";

import dayjs from "dayjs";
import ListItemIcon from "@mui/material/ListItemIcon";
import Attendee from "../../../MasterEventObjects/Attendee";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Unstable_Grid2";
import {Route, Routes, useNavigate} from "react-router-dom";
import DetailsView from "../../EventFeatures/Details/DetailsView";
import LocationDetails from "../../EventFeatures/Location/LocationDetails";
import DatesTimesContent from "../../EventFeatures/DatesTimes/DatesTimesContent";
import ItineraryView from "../../EventFeatures/Itinerary/ItineraryView";
import ActivitiesList from "../../EventFeatures/Activities/ActivitiesList";
import ActivitiesAdd from "../../EventFeatures/Activities/ActivitiesAdd";
import ActivitiesEdit from "../../EventFeatures/Activities/ActivitiesEdit";
import IdeasList from "../../EventFeatures/Ideas/IdeasList";
import AttendeesList from "../../EventFeatures/Attendees/AttendeesList";
import AttendeesAddNew from "../../EventFeatures/Attendees/AttendeesAddNew";
import AttendeesAddPrevious from "../../EventFeatures/Attendees/AttendeesAddPrevious";
import AttendeesAddSamples from "../../EventFeatures/Attendees/AttendeesAddSamples";
import AttendeeEdit from "../../EventFeatures/Attendees/AttendeeEdit";
import LodgingList from "../../EventFeatures/Lodging/LodgingList";
import LodgingAddFromMap from "../../EventFeatures/Lodging/LodgingAddFromMap";
import LodgingAddFromSuggestions from "../../EventFeatures/Lodging/LodgingAddFromSuggestions";
import LodgingAddFromPDF from "../../EventFeatures/Lodging/LodgingAddFromPDF";
import LodgingSuggestions from "../../EventFeatures/Lodging/LodgingSuggestions";
import FlightsList from "../../EventFeatures/Flights/FlightsList";
import MessagesList from "../../EventFeatures/Messages/MessagesList";
import MealsList from "../../EventFeatures/Meals/MealsList";
import PhotosList from "../../EventFeatures/Photos/PhotosList";
import PhotosAddNew from "../../EventFeatures/Photos/PhotosAddNew";
import SuggestionsTourRadar from "../../EventFeatures/Suggestions/SuggestionsTourRadar";
import SuggestionsUnique from "../../EventFeatures/Suggestions/SuggestionsUnique";
import SuppliesList from "../../EventFeatures/Supplies/SuppliesList";
import SuppliesAddEssentials from "../../EventFeatures/Supplies/SuppliesAddEssentials";
import SuppliesAddNew from "../../EventFeatures/Supplies/SuppliesAddNew";
import ReceiptList from "../../EventFeatures/Receipts/ReceiptList";
import TodoList from "../../EventFeatures/Todo/TodoList";
import WeatherDetails from "../../EventFeatures/Weather/WeatherDetails";
import PAAttendee from "../../../MasterEventObjects/Attendee";
import GoogleMap from "../../../Pages/PlanningPage/GoogleMap";
import Box from "@mui/material/Box";



function PlannerResponsive(props){

    const navigate = useNavigate();
    const { dataArray, setPlanning } = useContext(PAContext);
    const { Account, Planning, authenticated, Events } = dataArray;

    const [selectedMenu, setSelectedMenu] = useState(null); // Track selected mobile menu
    const [currentFeature, setCurrentFeature] = useState("Map");

    const [zoomInOnLocation, setZoomInOnLocation] = useState(props.zoomInOn);
    const [searchingForLocation, setSearchingForLocation] = useState(props.searchingFor);
    const [suggestionForLocation, setSuggestionForLocation] = useState("");

    const [showEventItemConfig, setShowEventItemConfig] = useState(false);
    const [itineraryViewSection, setItineraryViewSection] = useState("");
    const [itineraryViewType, setItineraryViewType] = useState("");

    const [eventBudgetTotal, setEventBudgetTotal] = useState(0);
    const [eventBudgetActual, setEventBudgetActual] = useState(0);
    const [eventBudgetCurrency, setEventBudgetCurrency] = useState(0);

    const [clearEvent, setClearEvent] = useState(false);
    const [eventName, setEventName] = useState(Planning.title || 'My New Event');
    const [eventBeginDate, setEventBeginDate] = useState(null);
    const [eventEndDate, setEventEndDate] = useState(null);
    const [formattedEventDates, setFormattedEventDates] = useState("");

    const [eventAttendeesUpdated, setAttendeesUpdated] = useState(false);
    const [editAttendeeId, setEditAttendeeId] = useState("");
    const [selectedAttendeeForSupplies, setSelectedAttendeeForSupplies] = useState("");

    const [ideasFromMap, setIdeasFromMap] = useState({});
    const [activitiesFromMap, setActivitiesFromMap] = useState({});
    const [lodgingFromMap, setLodgingFromMap] = useState({});
    const [mealsFromMap, setMealsFromMap] = useState({});
    const [conciergePlanningItemsFromMap, setConciergePlanningItemsFromMap] = useState([]);

    const [lodgingFromSuggestions, setLodgingFromSuggestions] = useState({});
    const [lodgingPhotosFromMap, setLodgingPhotosFromMap] = useState([]);
    const [viewSuggestionLodgingMarkers, setViewSuggestionLodgingMarkers] = useState(false);
    const [viewSuggestionLodgingMarkersFilter, setViewSuggestionLodgingMarkersFilter] = useState([]);
    const [lodgingSuggestionNameFromMap, setLodgingSuggestionNameFromMap] = useState("");

    const [pullWeatherReport, setPullWeatherReport] = useState(false);
    const [weatherReportReceived, setWeatherReportReceived] = useState(false);
    const [weatherReportReceivedSuccessOpen, setWeatherReportReceivedSuccessOpen] = useState(false);

    const [tourSuggestionsTourCities, setTourSuggestionsTourCities] = useState([]);


    /*
        for the Events menu list
     */
    useEffect(() => {
        console.log("clearEvent");

        if(clearEvent){
            setSearchingForLocation("");
            setFormattedEventDates("");

            //clear the budget summary
            setEventBudgetTotal(0);
            setEventBudgetActual(0);

            setClearEvent(false);
        }

    },[clearEvent]);

    const handleEventMenuClick = (event_id) => {
        console.log("handleEventMenuClick");
        console.log("event_id : "+event_id);

        setAnchorEventMenu(null);

        setClearEvent(true);

        //load the Event from the browser database
        getEventFromDatabase(event_id).then(specificEvent => {
            console.log("specificEvent : "+specificEvent);

            if (specificEvent !== null){
                const _event = new PAEvent();
                _event.id = specificEvent.id;
                _event.title = specificEvent.title;
                _event.coverImage = specificEvent.coverImage;
                _event.cover_image_thumbnail = specificEvent.cover_image_thumbnail;

                //convert the calendar if necessary
                const _existing_calendar = specificEvent.calendar;
                if (_existing_calendar){
                    const _time_table = new TimeTable();

                    const beginDate = dayjs(specificEvent.calendar.begin_date);
                    const endDate = dayjs(specificEvent.calendar.end_date);

                    const dateObjects = [];
                    const daysOfWeekSet = new Set(); // To store unique days of the week

                    // Iterate through each day between begin_date and end_date
                    let currentDate = beginDate;
                    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
                        const dateObj = {
                            date: currentDate.format('YYYY-MM-DD'),
                            dayOfWeek: currentDate.format('dddd'),
                            'all-day': true, // Assuming it's an all-day event by default
                            notes: '', // Initialize notes as an empty string
                        };

                        // Add the unique day of the week to the Set
                        daysOfWeekSet.add(currentDate.format('dddd'));

                        // Check if the day is the first day and if it's not an all-day event
                        if (currentDate.isSame(beginDate, 'day') && (beginDate.hour() !== 0 || beginDate.minute() !== 0)) {
                            dateObj['all-day'] = false;
                        }

                        // Check if the day is the last day and if it's not an all-day event
                        if (currentDate.isSame(endDate, 'day') && (endDate.hour() !== 23 || endDate.minute() !== 59)) {
                            dateObj['all-day'] = false;
                        }

                        // Set begin-time and end-time if they exist
                        if (currentDate.isSame(beginDate, 'day')) {
                            dateObj['begin-time'] = beginDate.format('h:mm A');
                        }
                        if (currentDate.isSame(endDate, 'day')) {
                            dateObj['end-time'] = endDate.format('h:mm A');
                        }

                        dateObjects.push(dateObj);
                        currentDate = currentDate.add(1, 'day'); // Move to the next day
                    }

                    // Update _time_table with dates
                    _time_table.dates = dateObjects;

                    // Populate _time_table.days with the unique days of the week from daysOfWeekSet
                    _time_table.days = Array.from(daysOfWeekSet).map((dayOfWeek, index) => ({
                        [`day ${index + 1}`]: dayOfWeek,
                        'all-day': true,
                        notes: '', // Initialize with empty notes
                    }));

                    _event.time_table = _time_table;

                } else {
                    _event.time_table = specificEvent.time_table;
                }

                //convert the attendees if necessary
                if (specificEvent.attendees.budget === undefined){
                    const updated_attendees = [];

                    for (const _attendee of specificEvent.attendees){
                        const _pa_attendee = new Attendee(
                            _attendee.fname,
                            _attendee.lname,
                            _attendee.email
                        );

                        _pa_attendee.id = _attendee.id;
                        _pa_attendee.role = _attendee.role;
                        _pa_attendee.image = _attendee.image;
                        _pa_attendee.nickname = _attendee.nickname;

                        _pa_attendee.address = _attendee.address;
                        _pa_attendee.city = _attendee.city;
                        _pa_attendee.state = _attendee.state;
                        _pa_attendee.zip = _attendee.zip;
                        _pa_attendee.country = _attendee.country;

                        _pa_attendee.phone_prefix = _attendee.phone_prefix;
                        _pa_attendee.phone = _attendee.phone;
                        _pa_attendee.mask_phone_number = _attendee.mask_phone_number;

                        _pa_attendee.push_notifications = _attendee.push_notifications;
                        _pa_attendee.push_keys = _attendee.push_keys;

                        _pa_attendee.rsvp_sent = _attendee.rsvp_sent;
                        _pa_attendee.rsvp = _attendee.rsvp;
                        _pa_attendee.rsvp_notes = _attendee.rsvp_notes;

                        _pa_attendee.notification = _attendee.notification;

                        _pa_attendee.documents = _attendee.documents;

                        _pa_attendee.last_check = _attendee.last_check;
                        _pa_attendee.status = _attendee.status;

                        updated_attendees.push(_pa_attendee);
                    }
                    _event.attendees = updated_attendees;
                } else {
                    _event.attendees = specificEvent.attendees;
                }

                //convert the lodging if necessary
                const _existing_lodging = specificEvent.lodging;
                if (_existing_lodging && _existing_lodging.length > 0){

                    if (_existing_lodging[0].location === undefined){
                        //need to convert
                        for (const _existing of _existing_lodging){
                            const _new_lodging = new Lodging();
                            _new_lodging.id = _existing.id;

                            _new_lodging.name = _existing.name;

                            _new_lodging.begin_date = _existing.begin_date;
                            _new_lodging.end_date = _existing.end_date;
                            _new_lodging.check_in = _existing.check_in;
                            _new_lodging.check_out = _existing.check_out;

                            //now figure out the time_table
                            _new_lodging.time_table = createTimeTableForLodging(
                                _existing.begin_date,
                                _existing.end_date,
                                _event.time_table);


                            //add the lodging location
                            const _lodging_location = new PALocation();
                            _lodging_location.google_place_id = _existing.google_place_id;
                            _lodging_location.latitude = _existing.latitude;
                            _lodging_location.longitude = _existing.longitude;

                            _lodging_location.name = _existing.name;
                            _lodging_location.formatted_address = "";
                            _lodging_location.address = _existing.address;
                            _lodging_location.city = _existing.city;
                            _lodging_location.state = _existing.state;
                            _lodging_location.zip = _existing.zip;
                            _lodging_location.country = _existing.country;

                            _new_lodging.location = _lodging_location;

                            _new_lodging.phone = _existing.phone;

                            _new_lodging.booked_through = _existing.booked_through;
                            _new_lodging.confirmation_number = _existing.confirmation_number;
                            _new_lodging.reservation_name = _existing.reservation_name;
                            _new_lodging.reservation_number = _existing.reservation_name;
                            _new_lodging.gate_code = _existing.gate_code;
                            _new_lodging.door_code = _existing.door_code;
                            _new_lodging.wifi_password = _existing.wifi_password;

                            for (const _att of _existing.attendees){
                                if (_att.costs === undefined){
                                    const _att_with_cost = {};
                                    _att_with_cost.id = _att;
                                    _att_with_cost.cost = 0;
                                    _new_lodging.attendees.push(_att_with_cost);
                                } else {
                                    _new_lodging.attendees.push(_att);
                                }

                            }

                            _new_lodging.amount_total = _existing.amount_total === undefined ? 0 : _existing.amount_total;
                            _new_lodging.currency = _existing.currency === undefined ? "USD" : _existing.currency;

                            _new_lodging.time_zone = _existing.time_zone;
                            _new_lodging.notes = _existing.notes;
                            _new_lodging.status = _existing.status;

                            _event.lodging.push(_new_lodging);
                        }
                    } else {
                        _event.lodging = specificEvent.lodging;
                    }
                }

                //convert the activities if necessary
                const _existing_activities = specificEvent.activities;
                if (_existing_activities && _existing_activities.length > 0) {

                    const _activities_list= _existing_activities.map(_activity => {

                        if (_activity.amount_total === undefined) {
                            console.log("the Event Activity neeeds to be converted");
                            const _new_activity = new Activity();
                            _new_activity.id = _activity.id;
                            _new_activity.title = _activity.title;
                            _new_activity.begin_date = _activity.begin_date;
                            _new_activity.end_date = _activity.end_date;
                            _new_activity.all_day_activity = _activity.all_day_activity;
                            _new_activity.begin_time = _activity.begin_time;
                            _new_activity.end_time = _activity.end_time;

                            //now figure out the time_table
                            _new_activity.time_table = createTimeTableForActivity(
                                _activity.begin_date,
                                _activity.end_date,
                                _activity.all_day_activity,
                                _activity.begin_time,
                                _activity.end_time,
                                _event.time_table);

                            //add the activity location
                            const _activity_location = new PALocation();
                            _activity_location.google_place_id = _activity.location.google_place_id;
                            _activity_location.latitude = _activity.location.latitude;
                            _activity_location.longitude = _activity.location.longitude;

                            _activity_location.name = _activity.location.name;
                            _activity_location.formatted_address = "";
                            _activity_location.address = _activity.location.address;
                            _activity_location.city = _activity.location.city;
                            _activity_location.state = _activity.location.state;
                            _activity_location.zip = _activity.location.zip;
                            _activity_location.country = _activity.location.country;

                            _new_activity.location = _activity_location;

                            for (const _att of _activity.attendees){
                                if (_att.costs === undefined){
                                    const _att_with_cost = {};
                                    _att_with_cost.id = _att;
                                    _att_with_cost.cost = 0;
                                    _new_activity.attendees.push(_att_with_cost);
                                } else {
                                    _new_activity.attendees.push(_att);
                                }
                            }

                            _new_activity.phone = _activity.location.phone;
                            _new_activity.booked_through = _activity.location.booked_through;
                            _new_activity.confirmation_number = _activity.location.confirmation_number;
                            _new_activity.reservation_number = _activity.location.reservation_number;
                            _new_activity.reservation_name = _activity.location.reservation_name;

                            _new_activity.details = _activity.details;
                            _new_activity.bring = _activity.bring;

                            _new_activity.amount_total = _activity.amount_total === undefined ? 0 : _activity.amount_total;
                            _new_activity.currency = _activity.currency === undefined ? "USD" : _activity.currency;

                            _new_activity.notes = _activity.notes;

                            _new_activity.status = _activity.status;

                            return _new_activity;

                        } else {
                            return _activity;
                        }

                    });
                    _event.activities = _activities_list;

                }

                //convert the meals if necessary
                const _existing_meals = specificEvent.meals;
                if (_existing_meals && _existing_meals.length > 0) {

                    const _meal_list= _existing_meals.map(_meal => {

                        if (_meal.eatery_name || _meal.eatery_name === "") {
                            // Convert old structure to new structure

                            const _new_meal = new Meal();
                            _new_meal.id = _meal.id;
                            _new_meal.name = _meal.meal_name;
                            _new_meal.date = _meal.meal_date;
                            _new_meal.time = _meal.meal_time;

                            //now figure out the time_table
                            _new_meal.time_table = createTimeTableForMeal(
                                _meal.meal_date,
                                _meal.meal_time,
                                _event.time_table);

                            //add the meal location
                            const _meal_location = new PALocation();
                            _meal_location.google_place_id = _meal.eatery_google_place_id;
                            _meal_location.latitude = _meal.eatery_latitude;
                            _meal_location.longitude = _meal.eatery_longitude;

                            _meal_location.name = _meal.eatery_name;
                            _meal_location.formatted_address = "";
                            _meal_location.address = _meal.eatery_address;
                            _meal_location.city = _meal.eatery_city;
                            _meal_location.state = _meal.eatery_state;
                            _meal_location.zip = _meal.eatery_zip;
                            _meal_location.country = _meal.eatery_country;

                            _new_meal.location = _meal_location;

                            _new_meal.phone = _meal.eatery_phone;
                            _new_meal.booked_through = _meal.booked_through;
                            _new_meal.confirmation_number = _meal.confirmation_number;
                            _new_meal.reservation_number = _meal.reservation_number;
                            _new_meal.reservation_name = _meal.reservation_name;

                            _new_meal.eat_in = _meal.eat_in;
                            _new_meal.eat_in_details = _meal.eat_in_details;
                            _new_meal.notes = _meal.eatery_notes;

                            for (const _att of _meal.attendees) {
                                if (_att.costs === undefined) {
                                    const _att_with_cost = {};
                                    _att_with_cost.id = _att;
                                    _att_with_cost.cost = 0;
                                    _new_meal.attendees.push(_att_with_cost);
                                } else {
                                    _new_meal.attendees.push(_att);
                                }
                            }

                            _new_meal.photos = [];
                            _new_meal.amount_total = _meal.amount_total === undefined ? 0 : _meal.amount_total;
                            _new_meal.currency = _meal.currency === undefined ? "USD" : _meal.currency;
                            _new_meal.assign_equal = _meal.assign_equal === undefined ? true : _meal.assign_equal;

                            _new_meal.status = _meal.status;

                            return _new_meal;
                        } else {
                            return _meal;
                        }

                    });

                    _event.meals = _meal_list;

                }

                //convert the ideas if necessary
                const _existing_ideas = specificEvent.ideas;
                if (_existing_ideas && _existing_ideas.length > 0) {

                    if (_existing_ideas[0].location === undefined) {
                        for (const _existing of _existing_ideas) {
                            const _new_idea = new Idea();
                            _new_idea.id = _existing.id;
                            _new_idea.index = _existing.index;
                            _new_idea.title = _existing.title;
                            _new_idea.category = _existing.category;

                            _new_idea.attendee_id = _existing.attendee_id;

                            _new_idea.url = _existing.url;

                            const _idea_location = new PALocation();
                            _idea_location.google_place_id = _existing.google_place_id;
                            _idea_location.latitude = _existing.latitude;
                            _idea_location.longitude = _existing.longitude;

                            _idea_location.name = _existing.name;
                            _idea_location.formatted_address = "";
                            _idea_location.address = _existing.address;
                            _idea_location.city = _existing.city;
                            _idea_location.state = _existing.state;
                            _idea_location.zip = _existing.zip;
                            _idea_location.country = _existing.country;

                            _new_idea.location = _idea_location;

                            _new_idea.phone = _existing.phone;

                            _new_idea.comments = _existing.comments;

                            _new_idea.status = _existing.status;

                            _event.ideas.push(_new_idea);
                        }
                    } else {
                        _event.ideas = specificEvent.ideas;
                    }
                }

                //convert the flights if necessary
                const _existing_flights = specificEvent.flights;
                if (_existing_flights && _existing_flights.length > 0) {

                    if (_existing_flights[0].amount_total === undefined) {
                        for (const _existing of _existing_flights) {
                            const _new_flight = new Flight();
                            _new_flight.id = _existing.id;
                            _new_flight.flight_group_id = _existing.flight_group_id;
                            _new_flight.ident = _existing.ident;
                            _new_flight.actual_ident = _existing.actual_ident;
                            _new_flight.flight_number = _existing.flight_number;

                            _new_flight.airline_code = _existing.airline_code;
                            _new_flight.airline_name = _existing.airline_name;

                            //now figure out the time_table
                            _new_flight.time_table = createTimeTableForFlight(
                                _existing.depart_timestamp,
                                _existing.arrive_timestamp,
                                _event.time_table);

                            _new_flight.flight_duration = _existing.flight_duration;

                            _new_flight.depart_airport_code = _existing.depart_airport_code;
                            _new_flight.depart_airport_name = _existing.depart_airport_name;
                            _new_flight.depart_airport_city = _existing.depart_airport_city;
                            _new_flight.depart_airport_latitude = _existing.depart_airport_latitude;
                            _new_flight.depart_airport_longitude = _existing.depart_airport_longitude;
                            _new_flight.depart_timestamp = _existing.depart_timestamp;
                            _new_flight.depart_timezone = _existing.depart_timezone;
                            _new_flight.depart_timezone_offset = _existing.depart_timezone_offset;

                            _new_flight.arrive_airport_code = _existing.arrive_airport_code;
                            _new_flight.arrive_airport_name = _existing.arrive_airport_name;
                            _new_flight.arrive_airport_city = _existing.arrive_airport_city;
                            _new_flight.arrive_airport_latitude = _existing.arrive_airport_latitude;
                            _new_flight.arrive_airport_longitude = _existing.arrive_airport_longitude;
                            _new_flight.arrive_timestamp = _existing.arrive_timestamp;
                            _new_flight.arrive_timezone = _existing.arrive_timezone;
                            _new_flight.arrive_timezone_offset = _existing.arrive_timezone_offset;

                            for (const _att of _existing.attendees){
                                if (_att.costs === undefined){
                                    const _att_with_cost = {};
                                    _att_with_cost.id = _att;
                                    _att_with_cost.cost = 0;
                                    _new_flight.attendees.push(_att_with_cost);
                                } else {
                                    _new_flight.attendees.push(_att);
                                }
                            }

                            _new_flight.amount_total = _existing.amount_total === undefined ? 0 : _existing.amount_total;
                            _new_flight.currency = _existing.currency === undefined ? "USD" : _existing.currency;

                            _new_flight.notes = "";
                            _new_flight.status = _existing.status;

                            _event.flights.push(_new_flight);
                        }
                    } else {
                        _event.flights = specificEvent.flights;
                    }
                }

                _event.supplies = specificEvent.supplies;
                _event.to_do = specificEvent.to_do;
                _event.documents = specificEvent.documents;
                _event.receipts = specificEvent.receipts;

                console.log("specificEvent.messages : "+JSON.stringify(specificEvent.messages, null, '\t'));
                _event.messages = specificEvent.messages === undefined ? [] : specificEvent.messages;
                console.log("s_event.messages : "+JSON.stringify(_event.messages, null, '\t'));

                _event.photos = specificEvent.photos;

                _event.need_to_save = specificEvent.need_to_save === undefined ? false : specificEvent.need_to_save;

                _event.version = 2.0;
                _event.last_mod = specificEvent.last_mod;

                _event.status = specificEvent.status;

                console.log("the new Event : "+JSON.stringify(_event, null, '\t'));

                //set the Event
                setPlanning(_event);


                //update the Event name display
                setEventName(specificEvent.name);

                //change map location
                // Create an object to store seen city/state pairs
                if (_existing_lodging && _existing_lodging.length > 0){
                    setZoomInOnLocation({
                        'google_place_id': _event.lodging[0].location.google_place_id,
                        'latitude': _event.lodging[0].location.latitude,
                        'longitude': _event.lodging[0].location.longitude
                    })
                    setSearchingForLocation(_event.lodging[0].location.city+" "+_event.lodging[0].location.state);
                }

                //update the budget
                setEventBudgetTotal(_event.budget_total);

                /*
                    check for relevant weather
                    which means anything in the past or anything that isn't more than
                    2 weeks in the future
                 */
                setPullWeatherReport(true);

                /*
                    //see if there are any NEW Event messages
                 */
                const _send_string = {};
                _send_string.action = "E_getEventMessages";
                _send_string.event_id = _event.id;

                if (_event.messages.length === 0){
                    _send_string.latest_message_timestamp = 0;
                } else {
                    _send_string.latest_message_timestamp = _event.messages.reduce((latest, message) => {
                        return message.created > latest ? message.created : latest;
                    }, 0);
                }

                dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");


                /*
                    let AI know of the Event switch?
                 */


            } else {
                //no Event found
                console.log("specificEvent NOT FOUND");

            }
        });

    }

    const createTimeTableForLodging = (begin_date, end_date, event_time_table) => {
        const timeTable = new TimeTable();

        // Extract event start and end dates from event.time_table.dates
        const eventStart = dayjs(event_time_table.dates[0].date, "YYYY-MM-DD"); // First date in event time_table
        const eventEnd = dayjs(event_time_table.dates[event_time_table.dates.length - 1].date, "YYYY-MM-DD"); // Last date in event time_table

        // Parse the begin and end dates for the lodging using dayjs
        const start = dayjs(begin_date, "MM/DD/YYYY");  // Lodging begin date
        const end = dayjs(end_date, "MM/DD/YYYY");      // Lodging end date

        // Check if the dates are valid
        if (!start.isValid() || !end.isValid() || !eventStart.isValid() || !eventEnd.isValid()) {
            console.error("Invalid dates provided for the lodging or event.");
            return timeTable;
        }

        // Generate lodging dates between the start and end dates, inclusive
        let currentDate = start;
        while (currentDate.isBefore(end) || currentDate.isSame(end, 'day')) {
            const dayOfWeek = currentDate.format("dddd"); // Store the day of the week

            // Calculate the day number relative to the event start date
            const dayNumber = currentDate.diff(eventStart, 'day') + 1;

            // Push the current date to the dates array
            timeTable.dates.push({
                date: currentDate.format("YYYY-MM-DD"),         // Store date in YYYY-MM-DD format
                dayOfWeek: dayOfWeek,                           // Store day of the week (e.g., "Monday")
                'all-day': true,                                // You can modify this based on your logic
                notes: "",                                      // You can add notes as needed
            });

            // Add the corresponding day to the TimeTable days
            timeTable.days.push({
                [`day ${dayNumber}`]: dayOfWeek,
                'all-day': true,
                notes: "",  // Additional notes can be added here
            });

            // Move to the next day
            currentDate = currentDate.add(1, 'day');
        }

        return timeTable;
    };

    const createTimeTableForActivity = (begin_date, end_date, all_day_activity, begin_time, end_time, event_time_table) => {
        const timeTable = new TimeTable();

        // Extract event start and end dates from event.time_table.dates
        const eventStart = dayjs(event_time_table.dates[0].date, "YYYY-MM-DD"); // First date in event time_table
        const eventEnd = dayjs(event_time_table.dates[event_time_table.dates.length - 1].date, "YYYY-MM-DD"); // Last date in event time_table

        // Parse the begin and end dates for the activity using dayjs
        const start = dayjs(begin_date, "MM/DD/YYYY");  // Activity begin date
        const end = dayjs(end_date, "MM/DD/YYYY");      // Activity end date

        // Check if the dates are valid
        if (!start.isValid() || !end.isValid() || !eventStart.isValid() || !eventEnd.isValid()) {
            console.error("Invalid dates provided for the activity or event.");
            return timeTable;
        }

        // Generate activity dates between the start and end dates, inclusive
        let currentDate = start;
        while (currentDate.isBefore(end) || currentDate.isSame(end, 'day')) {
            const dayOfWeek = currentDate.format("dddd"); // Store the day of the week

            // Calculate the day number relative to the event start date
            const dayNumber = currentDate.diff(eventStart, 'day') + 1;

            // Determine if it's an all-day event or has specific begin and end times
            const allDay = all_day_activity;
            const beginTime = !allDay ? begin_time : null;  // If it's not all-day, use the begin_time
            const endTime = !allDay ? end_time : null;      // If it's not all-day, use the end_time

            // Push the current date to the dates array
            timeTable.dates.push({
                date: currentDate.format("YYYY-MM-DD"),    // Store date in YYYY-MM-DD format
                dayOfWeek: dayOfWeek,                      // Store day of the week (e.g., "Monday")
                'all-day': allDay,                         // Set based on the all_day_activity flag
                begin_time: beginTime,                     // Begin time for non-all-day activities
                end_time: endTime,                         // End time for non-all-day activities
                notes: "",                                 // You can add notes as needed
            });

            // Add the corresponding day to the TimeTable days
            timeTable.days.push({
                [`day ${dayNumber}`]: dayOfWeek,
                'all-day': allDay,
                begin_time: beginTime,   // Include the begin_time for non-all-day activities
                end_time: endTime,       // Include the end_time for non-all-day activities
                notes: "",  // Additional notes can be added here
            });

            // Move to the next day
            currentDate = currentDate.add(1, 'day');
        }

        return timeTable;
    };

    const createTimeTableForMeal = (meal_date, meal_time, event_time_table) => {
        const timeTable = new TimeTable();

        // Extract event start and end dates from event_time_table.dates
        const eventStart = dayjs(event_time_table.dates[0].date, "YYYY-MM-DD"); // First date in event_time_table
        const eventEnd = dayjs(event_time_table.dates[event_time_table.dates.length - 1].date, "YYYY-MM-DD"); // Last date in event_time_table

        // Parse the meal_date using dayjs
        const mealDate = dayjs(meal_date, "MM/DD/YYYY");  // Meal date

        // Check if the date is valid
        if (!mealDate.isValid() || !eventStart.isValid() || !eventEnd.isValid()) {
            console.error("Invalid dates provided for the meal or event.");
            return timeTable;
        }

        // Ensure that the meal date is within the event timeline
        if (mealDate.isBefore(eventStart) || mealDate.isAfter(eventEnd)) {
            console.error("Meal date is outside of the event timeline.");
            return timeTable;
        }

        const dayOfWeek = mealDate.format("dddd"); // Store the day of the week

        // Calculate the day number relative to the event start date
        const dayNumber = mealDate.diff(eventStart, 'day') + 1;

        // Push the meal date to the dates array
        timeTable.dates.push({
            date: mealDate.format("YYYY-MM-DD"),      // Store date in YYYY-MM-DD format
            dayOfWeek: dayOfWeek,                     // Store day of the week (e.g., "Monday")
            'all-day': false,                         // Meal is not an all-day event
            begin_time: meal_time,                     // Store meal time
            notes: "",                                // You can add notes as needed
        });

        // Add the corresponding day to the TimeTable days
        timeTable.days.push({
            [`day ${dayNumber}`]: dayOfWeek,
            'all-day': false,
            begin_time: meal_time,  // Store meal time
            notes: "",  // Additional notes can be added here
        });

        return timeTable;
    };

    const createTimeTableForFlight = (depart_timestamp, arrive_timestamp, event_time_table) => {
        const timeTable = new TimeTable();

        // Extract event start and end dates from event_time_table.dates
        const eventStart = dayjs(event_time_table.dates[0].date, "YYYY-MM-DD"); // First date in event time_table
        const eventEnd = dayjs(event_time_table.dates[event_time_table.dates.length - 1].date, "YYYY-MM-DD"); // Last date in event time_table

        // Parse the depart and arrive timestamps
        const departDateTime = dayjs(depart_timestamp); // Flight departure timestamp
        const arriveDateTime = dayjs(arrive_timestamp); // Flight arrival timestamp

        // Check if the timestamps are valid
        if (!departDateTime.isValid() || !arriveDateTime.isValid() || !eventStart.isValid() || !eventEnd.isValid()) {
            console.error("Invalid timestamps or event dates provided.");
            return timeTable;
        }

        // Ensure that the flight's timestamps are within the event timeline
        if (departDateTime.isBefore(eventStart) || arriveDateTime.isAfter(eventEnd)) {
            console.error("Flight dates are outside of the event timeline.");
            return timeTable;
        }

        // Extract the day of the week for departure and arrival
        const departDayOfWeek = departDateTime.format("dddd"); // e.g., "Monday"
        const arriveDayOfWeek = arriveDateTime.format("dddd"); // e.g., "Tuesday"

        // Calculate the day number relative to the event start date
        const departDayNumber = departDateTime.diff(eventStart, 'day') + 1;
        const arriveDayNumber = arriveDateTime.diff(eventStart, 'day') + 1;

        // Add the departure information to the timeTable
        timeTable.dates.push({
            date: departDateTime.format("YYYY-MM-DD"),    // Departure date in YYYY-MM-DD format
            dayOfWeek: departDayOfWeek,                   // Day of the week for departure
            'all-day': false,                             // Flights are not all-day events
            depart_time: departDateTime.format('h:mm A'), // Format the departure time
            notes: "",                                    // Any additional notes can be added here
        });

        // Add the arrival information to the timeTable
        timeTable.dates.push({
            date: arriveDateTime.format("YYYY-MM-DD"),    // Arrival date in YYYY-MM-DD format
            dayOfWeek: arriveDayOfWeek,                   // Day of the week for arrival
            'all-day': false,                             // Flights are not all-day events
            arrive_time: arriveDateTime.format('h:mm A'), // Format the arrival time
            notes: "",                                    // Any additional notes can be added here
        });

        // Add the corresponding days to the TimeTable days
        timeTable.days.push({
            [`day ${departDayNumber}`]: departDayOfWeek,
            'all-day': false,
            depart_time: departDateTime.format('h:mm A'),
            notes: "",
        });

        timeTable.days.push({
            [`day ${arriveDayNumber}`]: arriveDayOfWeek,
            'all-day': false,
            arrive_time: arriveDateTime.format('h:mm A'),
            notes: "",
        });

        return timeTable;
    };
    function savePlanning(){

        //are they logged in
        if (authenticated && Account.user_id !== undefined){
            console.log("is logged in");

            //check if an Event name has been given
            //is this a bucket list item or an actual Event
            if (Planning.title === ""){
                //show the DialogBox to get the title

                const _sendString = {};
                _sendString.action = "E_setEventAllChanges";
                _sendString.event = Planning;

                dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

            }

        } else {
            console.log("needs to login");


        }

    }
    function clearPlanning(){
        //show the prompt
        setOpenDialogDelete(true);

    }
    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    function handleDialogDeleteClose() {
        setOpenDialogDelete(false);
    }
    function handleDialogDeleteOK(){
        setOpenDialogDelete(false);

        const _new_event = new PAEvent();
        _new_event.title = "My New Event";
        _new_event.status = 10;

        //see if they are logged in
        if (Object.keys(Account).length === 0){
            //create the default Planner Attendee
            console.log("Need to create the default Planner Attendee");
            const _planner = new PAAttendee(
                'Sample',
                'Planning',
                'sample.planning@plannedadventure.com');
            _planner.role = 'planner';
            _planner.nickname = 'Planner';
            _new_event.attendees.push(_planner);
        } else {
            //they are logged in so use their account
            console.log("User is logged in");

            const _planner = new PAAttendee(
                Account.fname,
                Account.lname,
                Account.email);
            _planner.role = 'planner';
            _planner.nickname = 'Planner';

            _planner.address = Account.address;
            _planner.city = Account.city;
            _planner.state = Account.state;
            _planner.zip = Account.zip;
            _planner.country = Account.country;

            _new_event.attendees.push(_planner);

        }

        setPlanning(_new_event);

    }


    /*
        for the Account menu list
     */
    const handleAccountMenuClick = (item) => {

    }


    /*
        for the library icon
     */
    const [anchorLibraryMenu, setAnchorLibraryMenu] = useState(null);
    const handleLibraryMenuOpen = (event) => {
        setAnchorLibraryMenu(event.currentTarget);
    };
    const handleLibraryMenuClose = () => {
        setAnchorLibraryMenu(null);
    };


    /*
        for the Features icon
     */
    const [anchorFeaturesMenu, setAnchorFeaturesMenu] = useState(null);
    const handleFeaturesMenuOpen = (event) => {
        setAnchorFeaturesMenu(event.currentTarget);
    };
    const handleFeaturesMenuClose = () => {
        setAnchorFeaturesMenu(null);
    };
    const handleEventFeaturesMenuClick = (featureName) => {
        // Handle the click event here
        console.log(`Clicked on ${featureName}`);
        setAnchorFeaturesMenu(null);

        //decide whether to show the console or not
        if (featureName !== "Save" && featureName !== "Clear"){
            if (!showEventItemConfig){
                //if it's not showing then show it
                setShowEventItemConfig(true);
                setCurrentFeature(featureName);

            } else {
                //if it is showing then decide whether it is just changing features and needs to stay open
                // - or - if they are just meaning to close it
                if (featureName === currentFeature){
                    setShowEventItemConfig(false);
                    setCurrentFeature("Map");
                } else {
                    setCurrentFeature(featureName);
                }
            }
        }
        switch (featureName){
            case "Save":
                savePlanning();
                break;
            case "Details":
                navigate('/Planner/Details')
                break;
            case "Location":
                navigate('/Planner/Location')
                break;
            case "Dates":
                navigate('/Planner/Dates')
                break;
            case "Map":
                navigate('/Planner/Map')
                break;
            case "Itinerary":
                navigate('/Planner/Itinerary')
                break;
            case "Documents":
                navigate('/Planner/Documents')
                break;
            case "Activities":
                navigate('/Planner/Activities')
                break;
            case "Ideas":
                navigate('/Planner/Ideas')
                break;
            case "Attendees":
                navigate('/Planner/Attendees')
                break;
            case "Lodging":
                navigate('/Planner/Lodging')
                break;
            case "Flights":
                navigate('/Planner/Flights')
                break;
            case "carRentals":
                navigate('/Planner/CarRentals')
                break;
            case "Cruises":
                navigate('/Planner/Cruises')
                break;
            case "Messages":
                navigate('/Planner/Messages')
                break;
            case "Meals":
                navigate('/Planner/Meals')
                break;
            case "Photos":
                navigate('/Planner/Photos')
                break;
            case "Supplies":
                navigate('/Planner/Supplies')
                break;
            case "Receipts":
                navigate('/Planner/Receipts')
                break;
            case "To-Do":
                navigate('/Planner/Todo')
                break;
            case "Weather":
                navigate('/Planner/Weather')
                break;
            case "Clear":
                clearPlanning();
                break;
            default:
        }

    }

    /*
        for the Account icon
    */
    const [anchorAccountMenu, setAnchorAccountMenu] = useState(null);
    const handleAccountMenuOpen = (event) => {
        setAnchorAccountMenu(event.currentTarget);
    };
    const handleAccountMenuClose = () => {
        setAnchorAccountMenu(null);
    };


    /*
        for the Event Upcoming submenu
     */
    const [anchorEventMenu, setAnchorEventMenu] = useState(null);
    const [submenuEventUpcomingOpen, setSubmenuEventUpcomingOpen] = useState(false);
    const handleEventMenuOpen = (event) => {
        setAnchorEventMenu(event.currentTarget);
    };    const handleEventMenuClose = () => {
        setAnchorEventMenu(null);
    };
    const toggleEventUpcomingSubmenu = () => {
        setSubmenuEventUpcomingOpen(!submenuEventUpcomingOpen);
    };

    /*
        for the Event Past submenu
     */
    const [submenuEventPastOpen, setSubmenuEventPastOpen] = useState(false);
    const toggleEventPastSubmenu = () => {
        setSubmenuEventPastOpen(!submenuEventPastOpen);
    };



    return (
        <div className="dashboard-container">


            {/* this is the space to view/add/edit/delete any of the features */}
            <Grid
                container
                sx={{
                    height: '100vh', // Full viewport height
                }}
            >
                <Grid
                    item
                    sx={{
                        flexGrow: 1, // Fill available space
                        overflowY: 'auto', // Allow scrolling if content overflows
                        paddingBottom: '80px', // Leave space for the bottom menu (adjust to your menu height)
                    }}
                >
                    <div className="planning-event-routes-holder">
                        <Paper style={{
                            backgroundColor: 'rgb(251, 251, 251)',
                            overflowX: 'hidden',
                            overflowY: 'auto'
                        }}
                        >
                            <Routes>
                                <Route
                                    path="Details"
                                    element={
                                        <DetailsView
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setEventName={setEventName}
                                        />}
                                />
                                <Route
                                    path="Location"
                                    element={
                                        <LocationDetails
                                            setZoomInOnLocation={setZoomInOnLocation}
                                            searchingForLocation={searchingForLocation}
                                            setSearchingForLocation={setSearchingForLocation}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setPullWeatherReport={setPullWeatherReport}
                                            _global_map_ref={props._global_map_ref}
                                        />}
                                />

                                <Route
                                    path="Dates"
                                    element={
                                        <DatesTimesContent
                                            setEventName={setEventName}
                                            setEventBeginDate={setEventBeginDate}
                                            setEventEndDate={setEventEndDate}
                                            setPullWeatherReport={setPullWeatherReport}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Itinerary/*"
                                    element={
                                        <ItineraryView
                                            setItineraryViewSection={setItineraryViewSection}
                                            setItineraryViewType={setItineraryViewType}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Activities"
                                    element={
                                        <ActivitiesList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />
                                <Route path="Activities/Add" element={<ActivitiesAdd />} />
                                <Route path="Activities/Edit" element={<ActivitiesEdit />} />

                                <Route
                                    path="Ideas"
                                    element={
                                        <IdeasList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />

                                <Route
                                    path="Attendees"
                                    element={
                                        <AttendeesList
                                            setEditAttendeeId={setEditAttendeeId}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route path="Attendees/AddNew" element={<AttendeesAddNew setAttendeesUpdated={setAttendeesUpdated}/>} />
                                <Route path="Attendees/AddPrevious" element={<AttendeesAddPrevious setAttendeesUpdated={setAttendeesUpdated} />} />
                                <Route path="Attendees/AddSamples" element={<AttendeesAddSamples setAttendeesUpdated={setAttendeesUpdated} />} />
                                <Route path="Attendees/Edit" element={<AttendeeEdit editAttendeeId={editAttendeeId}/>} />



                                <Route
                                    path="Lodging"
                                    element={
                                        <LodgingList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />
                                <Route
                                    path="Lodging/AddFromMap"
                                    element={
                                        <LodgingAddFromMap
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            lodgingFromMap={lodgingFromMap}
                                            lodgingPhotosFromMap={lodgingPhotosFromMap}
                                            eventBeginDate={eventBeginDate}
                                            eventEndDate={eventEndDate}
                                            setEventBeginDate={setEventBeginDate}
                                            setEventEndDate={setEventEndDate}
                                            eventBudgetActual={eventBudgetActual}
                                            setEventBudgetActual={setEventBudgetActual}
                                            setEventBudgetCurrency={setEventBudgetCurrency}
                                        />
                                    }
                                />
                                <Route
                                    path="Lodging/AddFromSuggestions"
                                    element={
                                        <LodgingAddFromSuggestions
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            lodgingFromSuggestions={lodgingFromSuggestions}
                                            eventBeginDate={eventBeginDate}
                                            eventEndDate={eventEndDate}
                                            setEventBeginDate={setEventBeginDate}
                                            setEventEndDate={setEventEndDate}
                                            eventBudgetActual={eventBudgetActual}
                                            setEventBudgetActual={setEventBudgetActual}
                                        />
                                    }
                                />
                                <Route
                                    path="Lodging/AddFromPDF"
                                    element={
                                        <LodgingAddFromPDF
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route
                                    path="Lodging/Suggestions"
                                    element={
                                        <LodgingSuggestions
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setViewSuggestionLodgingMarkersFilter={setViewSuggestionLodgingMarkersFilter}
                                            lodgingSuggestionNameFromMap={lodgingSuggestionNameFromMap}
                                            setCurrentFeature={setCurrentFeature}
                                            setLodgingFromSuggestions={setLodgingFromSuggestions}
                                        />
                                    }
                                />

                                <Route
                                    path="Flights"
                                    element={
                                        <FlightsList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route path="Flights/SearchAndBook" element={<AttendeesAddNew setAttendeesUpdated={setAttendeesUpdated}/>} />
                                <Route path="Flights/Search" element={<AttendeesAddNew setAttendeesUpdated={setAttendeesUpdated}/>} />
                                <Route path="Flights/EnterDetails" element={<AttendeesAddNew setAttendeesUpdated={setAttendeesUpdated}/>} />

                                <Route
                                    path="Map"
                                    element={
                                        <GoogleMap
                                            _global_map_ref={props._global_map_ref}
                                            zoomInOnLocation={zoomInOnLocation}
                                            searchingForLocation={searchingForLocation}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setCurrentFeature={setCurrentFeature}
                                            viewSuggestionLodgingMarkers={viewSuggestionLodgingMarkers}
                                            viewSuggestionLodgingMarkersFilter={viewSuggestionLodgingMarkersFilter}
                                            setLodgingSuggestionNameFromMap={setLodgingSuggestionNameFromMap}
                                            setIdeasFromMap={setIdeasFromMap}
                                            setLodgingFromMap={setLodgingFromMap}
                                            setLodgingPhotosFromMap={setLodgingPhotosFromMap}
                                            setMealsFromMap={setMealsFromMap}
                                            conciergePlanningItemsFromMap={conciergePlanningItemsFromMap}
                                            setConciergePlanningItemsFromMap={setConciergePlanningItemsFromMap}
                                            tourSuggestionsTourCities={tourSuggestionsTourCities}
                                            clearEvent={clearEvent}
                                        />
                                    }
                                />


                                <Route
                                    path="Messages"
                                    element={
                                        <MessagesList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Meals"
                                    element={
                                        <MealsList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />

                                <Route
                                    path="Photos"
                                    element={
                                        <PhotosList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route
                                    path="Photos/AddNew"
                                    element={
                                        <PhotosAddNew />
                                    }
                                />

                                <Route
                                    path="Suggestions/Tours"
                                    element={
                                        <SuggestionsTourRadar
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setTourSuggestionsTourCities={setTourSuggestionsTourCities}
                                        />
                                    }
                                />

                                <Route
                                    path="Suggestions/Unique"
                                    element={
                                        <SuggestionsUnique
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />


                                <Route
                                    path="Supplies/*"
                                    element={
                                        <SuppliesList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            selectedAttendeeForSupplies={selectedAttendeeForSupplies}
                                            setSelectedAttendeeForSupplies={setSelectedAttendeeForSupplies}
                                        />}
                                />
                                <Route path="Supplies/AddEssentials" element={<SuppliesAddEssentials />} />
                                <Route path="Supplies/AddNew" element={<SuppliesAddNew />} />

                                <Route
                                    path="Receipts"
                                    element={
                                        <ReceiptList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Todo"
                                    element={
                                        <TodoList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Weather"
                                    element={
                                        <WeatherDetails
                                            pullWeatherReport={pullWeatherReport}
                                            setWeatherReportReceived={setWeatherReportReceived}
                                            setWeatherReportReceivedSuccessOpen={setWeatherReportReceivedSuccessOpen}
                                        />}
                                />


                            </Routes>
                        </Paper>
                    </div>
                </Grid>
            </Grid>

            <div className="mobile-menu-content-container">

                <div className="mobile-bottom-row">
                    {/* Replace with 4 clickable divs in mobile */}
                    <div className="mobile-menu-item" onClick={handleFeaturesMenuOpen}>
                        <i className="icon-home3"></i>
                        <span>Home</span>
                    </div>

                    <div className="mobile-menu-item">
                        <i className="icon-bubbles2"></i>
                        <span>Messaging</span>
                    </div>

                    {/* the Event list  */}
                    <div className="mobile-menu-item" onClick={handleEventMenuOpen}>
                        <i className="icon-road-sign"></i>
                        <span>Events</span>
                    </div>
                    <Menu
                        sx={{ width: 320, maxWidth: '100%' }}
                        anchorEl={anchorEventMenu}
                        id="event-menu"
                        open={Boolean(anchorEventMenu)}
                        onClose={handleEventMenuClose}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuList dense sx={{ width: 320, maxWidth: '100%' }}>
                            {/* Sort the events array based on begin_date before mapping */}
                            {
                                Events
                                    .sort((a, b) => {
                                        const aFirstDate = dayjs(a.time_table?.dates?.[0]?.date); // First date of event A
                                        const bFirstDate = dayjs(b.time_table?.dates?.[0]?.date); // First date of event B

                                        // If either event has no dates, sort them to the end of the list
                                        if (!aFirstDate.isValid()) return 1;
                                        if (!bFirstDate.isValid()) return -1;

                                        // Compare the dates
                                        return bFirstDate.diff(aFirstDate);
                                    })
                                    .map(_event => {
                                        // Get dates from time_table
                                        const eventDates = _event.time_table?.dates;
                                        const eventDays = _event.time_table?.days;

                                        // Display dates if they exist
                                        let dateRangeDisplay = '';
                                        if (eventDates?.length > 0) {
                                            const beginDate = dayjs(eventDates[0].date);
                                            const endDate = dayjs(eventDates[eventDates.length - 1].date);

                                            if (beginDate.isSame(endDate, 'day')) {
                                                // Single date event
                                                dateRangeDisplay = beginDate.format('MMM D, YYYY');
                                            } else {
                                                // Date range
                                                dateRangeDisplay = `${beginDate.format('MMM D, YYYY')} - ${endDate.format('MMM D, YYYY')}`;
                                            }
                                        }

                                        // Display number of days if days exist
                                        let dayRangeDisplay = '';
                                        if (eventDays?.length > 0) {
                                            const dayNames = eventDays.map(dayObj => Object.values(dayObj)[0].slice(0, 3)).join('-'); // e.g., "Mon-Tue-Wed"
                                            dayRangeDisplay = `${eventDays.length} day${eventDays.length > 1 ? 's' : ''} ${dayNames}`;
                                        }

                                        // Calculate the number of days for date range
                                        let daysBetween = 0;
                                        if (eventDates?.length > 0) {
                                            const beginDate = dayjs(eventDates[0].date);
                                            const endDate = dayjs(eventDates[eventDates.length - 1].date);
                                            daysBetween = endDate.diff(beginDate, 'day') + 1; // +1 to include the end date
                                        }

                                        return (
                                            <MenuItem key={_event.id} onClick={() => handleEventMenuClick(_event.id)}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                    {/* Render event name on one line */}
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            whiteSpace: 'normal',
                                                            wordBreak: 'break-word', // Ensure long words wrap properly
                                                        }}
                                                    >
                                                        {_event.title}
                                                    </Typography>

                                                    {/* Render dates/days on the next line */}
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        {/* Left-aligned dates */}
                                                        <Typography
                                                            variant="caption"
                                                            color="text.secondary"
                                                            sx={{
                                                                whiteSpace: 'normal',
                                                                wordBreak: 'break-word',
                                                            }}
                                                        >
                                                            {dateRangeDisplay || dayRangeDisplay || 'No Dates or Days'}
                                                        </Typography>

                                                        {/* Right-aligned number of days */}
                                                        <Typography
                                                            variant="caption"
                                                            color="text.secondary"
                                                            sx={{ textAlign: 'right' }}
                                                        >
                                                            {daysBetween > 0 ? `${daysBetween} day${daysBetween > 1 ? 's' : ''}` : ''}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </MenuItem>
                                        );
                                    })
                            }
                        </MenuList>

                    </Menu>

                    {/* the Event features list; only shows if there is an Event selected  */}
                    {
                        Planning['title'].length > 0 ? (
                            <>
                                <div className="mobile-menu-item" onClick={handleFeaturesMenuOpen}>
                                    <i className="icon-helm"></i>
                                    <span>Features</span>
                                </div>
                                <Menu
                                    sx={{maxWidth: '100%'}}
                                    anchorEl={anchorFeaturesMenu}
                                    id="features-menu"
                                    open={Boolean(anchorFeaturesMenu)}
                                    onClose={handleFeaturesMenuClose}

                                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                                >
                                    <MenuList dense sx={{width: 320, maxWidth: '100%'}}>
                                        <MenuItem key="event_itinerary"
                                                  onClick={() => handleEventFeaturesMenuClick('Itinerary')}>
                                            <ListItemIcon>{<i className="icon-calendar"/>}</ListItemIcon>
                                            <ListItemText>Itinerary</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}/>
                                        </MenuItem>
                                        <MenuItem key="event_map" onClick={() => handleEventFeaturesMenuClick('Map')}>
                                            <ListItemIcon>{<i className="icon-map"/>}</ListItemIcon>
                                            <ListItemText>Map</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}/>
                                        </MenuItem>
                                        <MenuItem key="event_details" onClick={() => handleEventFeaturesMenuClick('Details')}>
                                            <ListItemIcon>{<i className="icon-settings-icons"/>}</ListItemIcon>
                                            <ListItemText>Cover</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}/>
                                        </MenuItem>
                                        <MenuItem key="event_budget" onClick={() => handleEventFeaturesMenuClick('Budget')}>
                                            <ListItemIcon>{<i className="icon-wallet"/>}</ListItemIcon>
                                            <ListItemText>Budget</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_time_table"
                                                  onClick={() => handleEventFeaturesMenuClick('Dates')}>
                                            <ListItemIcon>{<i className="icon-calendar3"/>}</ListItemIcon>
                                            <ListItemText>Dates/Days</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}/>
                                        </MenuItem>

                                        <MenuItem key="event_documents"
                                                  onClick={() => handleEventFeaturesMenuClick('Documents')}>
                                            <ListItemIcon>{<i className="icon-file-check"/>}</ListItemIcon>
                                            <ListItemText>Documents</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    Object.keys(Planning)?.length !== 0 ? (
                                                        Planning.documents?.length
                                                    ) : null
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_activities"
                                                  onClick={() => handleEventFeaturesMenuClick('Activities')}>
                                            <ListItemIcon>{<i className="icon-list-heart"/>}</ListItemIcon>
                                            <ListItemText>Activities</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    Object.keys(Planning)?.length !== 0 ? (
                                                        Planning.activities?.length
                                                    ) : null
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_ideas" onClick={() => handleEventFeaturesMenuClick('Ideas')}>
                                            <ListItemIcon>{<i className="icon-lamp-bright"/>}</ListItemIcon>
                                            <ListItemText>Ideas</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    Object.keys(Planning)?.length !== 0 ? (
                                                        Planning.ideas?.length
                                                    ) : null
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_attendees"
                                                  onClick={() => handleEventFeaturesMenuClick('Attendees')}>
                                            <ListItemIcon>{<i className="icon-user-24"/>}</ListItemIcon>
                                            <ListItemText>Attendees</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    Object.keys(Planning)?.length !== 0
                                                        ? Planning.attendees === undefined
                                                            ? 0
                                                            : Planning.attendees.filter(attendee => attendee.status !== 4).length
                                                        : 0
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_lodging" onClick={() => handleEventFeaturesMenuClick('Lodging')}>
                                            <ListItemIcon>{<i className="icon-bed"/>}</ListItemIcon>
                                            <ListItemText>Lodging</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    Object.keys(Planning)?.length !== 0 ? (
                                                        Planning.lodging?.length
                                                    ) : null
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_flights" onClick={() => handleEventFeaturesMenuClick('Flights')}>
                                            <ListItemIcon>{<i className="icon-airplane"/>}</ListItemIcon>
                                            <ListItemText>Flights</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    Object.keys(Planning)?.length !== 0 ? (
                                                        Planning.flights?.length
                                                    ) : null
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_messages" onClick={() => handleEventFeaturesMenuClick('Messages')}>
                                            <ListItemIcon>{<i className="icon-smartphone-text"/>}</ListItemIcon>
                                            <ListItemText>Messages</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    Object.keys(Planning)?.length !== 0 ? (
                                                        Planning.messages?.length
                                                    ) : null
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_meals" onClick={() => handleEventFeaturesMenuClick('Meals')}>
                                            <ListItemIcon>{<i className="icon-fork-knife2"/>}</ListItemIcon>
                                            <ListItemText>Meals</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    Object.keys(Planning)?.length !== 0 ? (
                                                        Planning.meals?.length
                                                    ) : null
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_photos" onClick={() => handleEventFeaturesMenuClick('Photos')}>
                                            <ListItemIcon>{<i className="icon-picture2"/>}</ListItemIcon>
                                            <ListItemText>Photos</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    Object.keys(Planning)?.length !== 0 ? (
                                                        Planning.photos?.length
                                                    ) : null
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_supplies" onClick={() => handleEventFeaturesMenuClick('Supplies')}>
                                            <ListItemIcon>{<i className="icon-checklist2"/>}</ListItemIcon>
                                            <ListItemText>Supplies</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    Object.keys(Planning)?.length !== 0 ? (
                                                        Planning.supplies?.length
                                                    ) : null
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_receipts" onClick={() => handleEventFeaturesMenuClick('Receipts')}>
                                            <ListItemIcon>{<i className="icon-receipt"/>}</ListItemIcon>
                                            <ListItemText>Receipts</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    Object.keys(Planning)?.length !== 0 ? (
                                                        Planning.receipts?.length
                                                    ) : null
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_todo" onClick={() => handleEventFeaturesMenuClick('Todo')}>
                                            <ListItemIcon>{<i className="icon-checklist-time"/>}</ListItemIcon>
                                            <ListItemText>To-Do</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                                {
                                                    Object.keys(Planning)?.length !== 0 ? (
                                                        Planning.to_do?.length
                                                    ) : null
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem key="event_weather" onClick={() => handleEventFeaturesMenuClick('Weather')}>
                                            <ListItemIcon>{<i className="icon-sun-wind"/>}</ListItemIcon>
                                            <ListItemText>Weather</ListItemText>
                                            <Typography variant="caption" sx={{color: 'text.secondary'}}/>
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </>
                        ) : (
                            <></>
                        )
                    }

                    {/* the Account list  */}
                    <div className="mobile-menu-item">
                        <i className="icon-business-card"></i>
                        <span>Account</span>
                    </div>

                </div>
            </div>

            <Dialog
                open={openDialogDelete}
                onClose={handleDialogDeleteClose}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to clear all of the information for this Event?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogDeleteClose}>Cancel</Button>
                    <Button onClick={handleDialogDeleteOK} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    );

}

export default PlannerResponsive

