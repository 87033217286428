
import React, {useContext, useState} from 'react';

import {Container} from "@mui/material";

import './planning.css';
import PlannerContent from "./PlannerContent";
import PlannerResponsive from "./PlannerResponsive";
import {PAContext} from "../../../Services/PAContext";



function Planner(props) {

    const { dataArray } = useContext(PAContext);
    const { isMobileView } = dataArray;

    const [lookingAt, setLookingAt] = useState('Planner');
    const [zoomInOn, setZoomInOn] = useState({});

    return (

        <Container
            sx={{
                maxWidth: '100vw !important',
            }}
        >
            {
                isMobileView ? (
                    <PlannerResponsive
                        lookingAt={lookingAt}
                        isSearching={props.isSearching}
                        searchingFor={props.searchingFor}
                        zoomInOn={zoomInOn}
                        _global_map_ref={props._global_map_ref}
                    />
                ) : (
                    <PlannerContent
                        lookingAt={lookingAt}
                        isSearching={props.isSearching}
                        searchingFor={props.searchingFor}
                        zoomInOn={zoomInOn}
                        _global_map_ref={props._global_map_ref}
                    />
                )
            }

        </Container>

    )

}

export default Planner;
